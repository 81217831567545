import { Box, Drawer, Grid } from "@mui/material";
import Controls from "../../components/controls/Controls";
import * as Mascaras from '../../util/Mascaras'

const tiposImovel = [{}]


export default function Filtros(props) {
    const { values, handleInputChange, setValues, filtrar, erros, alterarMenu, isMobile, menu } = props

    const handleChangeMaiorPreco = (e) => {
        setValues({ ...values, maiorPreco: Mascaras.digitos(e.target.value) })
    }

    const handleChangeMenorPreco = (e) => {
        setValues({ ...values, menorPreco: Mascaras.digitos(e.target.value) })
    }

    const handleCheckbox = (e) => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    const handlePesquisar = async () => {
        await filtrar(values)
        alterarMenu()
    }

    return (
        <Drawer
            anchor={'left'}
            open={menu}
            PaperProps={{
                sx: { height: '100%', width: isMobile ? '40%' : '15%' },
            }}
            onClose={e => { alterarMenu() }}
        >
            <Box sx={{ padding: '5px', paddingInline: isMobile ? '5px' : '10px' }}>
                <Grid container spacing={2} sx={{ display: 'flex' }}>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            startInputPropText='R$'
                            name="maiorPreco"
                            label='Maior preço'
                            value={values.maiorPreco}
                            onChange={(e) => handleChangeMaiorPreco(e)}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            startInputPropText='R$'
                            name="menorPreco"
                            label='Menor preço'
                            value={values.menorPreco}
                            onChange={(e) => handleChangeMenorPreco(e)}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            startInputPropText='m²'
                            name="maiorArea"
                            label='Maior Área'
                            value={values.maiorArea}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Input
                            startInputPropText='m²'
                            name="menorArea"
                            label='Menor Área'
                            value={values.menorArea}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.SelectTipoImovel
                            value={values.tipoImovel}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.SelectBairro
                            value={values.bairro}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Checkbox
                            name='vivareal'
                            label='VivaReal'
                            onChange={(e) => { handleCheckbox(e) }}
                            value={values.vivareal}
                            error={erros.vivareal}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Checkbox
                            name='leiloes'
                            label='Leilões'
                            onChange={(e) => { handleCheckbox(e) }}
                            value={values.leiloes}
                            error={erros.leiloes}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Checkbox
                            name='caixa'
                            label='Caixa'
                            onChange={(e) => { handleCheckbox(e) }}
                            value={values.caixa}
                            error={erros.caixa}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controls.Button
                            text='Pesquisar'
                            variant='outlined'
                            sx={{ background: 'white' }}
                            onClick={(e) => handlePesquisar(e)}
                        />
                    </Grid>
                    {/* <Grid item md={10} xs={4}>
                    <Controls.Button
                        text='Indicar!'
                        variant='outlined'
                        sx={{ background: 'white' }}
                        onClick={() => indicarImoveis()}
                    />
                </Grid> */}
                </Grid>
            </Box>
        </Drawer>
    )
}