import Controls from '../Controls';
import { useEffect, useState } from 'react';
import { createClient } from "@supabase/supabase-js";

const supabase = createClient("https://urksffqejgoyacvhltis.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVya3NmZnFlamdveWFjdmhsdGlzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQ4ODUxMDQsImV4cCI6MjA1MDQ2MTEwNH0.6LnuPY7mnKoHMMIuCYpZx72mBgcaauJDTKPhjZ10yxI");

export default function SelectBairro(props) {

    const { value, error = null, onChange, sx, uf, ...other } = props;
    const [listaBairro, setListaBairro] = useState([])

    const buscarBairros = async () => {
        let { data, error } = await supabase
            .from('bairros')
            .select('*')
        console.log('foi')
        setListaBairro(data.map((bairro) => {
            return ({
                id: bairro.id,
                value: bairro.nome
            })
        }));
    }

    useEffect(() => {
        buscarBairros()
    }, []);


    return (
        <Controls.Select
            name='bairro'
            label='Bairro'
            value={value}
            onChange={onChange}
            options={listaBairro}
            error={error}
            sx={sx}
            {...other}
        />
    )
}

