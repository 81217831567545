import { Box, Drawer, Typography } from "@mui/material";
import * as Mascaras from '../../util/Mascaras';

export default function InfoWindowLocal(props) {
    const { points, handleClickOutside, isMobile } = props
    return (

        <Drawer
            anchor={isMobile ? 'bottom' : 'right'}
            open={points.length > 0}
            PaperProps={{
                sx: { height: isMobile ? '37%' : '100%', width: isMobile ? '100%' : '29%' },
            }}
            onClose={e => { handleClickOutside() }}
        >
            {points.map((point, index) => {
                return (
                    <Box key={index} sx={{ marginTop: '8px', textAlign: 'center' }}>
                        <Typography><a href={point.url} target="_blank">{point.titulo}</a></Typography>
                        <Typography>{point.endereco}</Typography>
                        <Typography><strong>{Mascaras.dinheiroCifra(point.valor_venda)}</strong></Typography>
                        {(point.valor_original > 0 && point.valor_original !== point.valor_venda) && <Typography><s>{Mascaras.dinheiroCifra(point.valor_original)}</s></Typography>}
                        <a href={point.url} target="_blank"><img style={{ borderRadius: '5px' }} className="main-card" height='200px' width={'250px'} data-src={point.imagem_url} src={point.imagem_url} /></a>
                    </Box>
                )
            })
            }
        </Drawer>
    )
}