import Controls from '../Controls';

const listaTipoImovel = [
    {id: 0, value: 'Todos'},
    {id: 1, value: 'Casa'},
    {id: 2, value: 'Apartamento'},
    {id: 3, value: 'Terreno'},
    {id: 4, value: 'Cobertura'},
    {id: 6, value: 'Comercial'},,
    {id: 5, value: 'Outros'}
]

export default function SelectTipoImovel(props) {

    const { value, error = null, onChange, sx, uf, ...other } = props;

    return (
        <Controls.Select
            name='tipoImovel'
            label='Tipo Imóvel'
            value={value}
            onChange={onChange}
            options={listaTipoImovel}
            error={error}
            sx={sx}
            {...other}
        />
    )
}

