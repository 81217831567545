import axios from "axios";
import * as Utils from "../util/Utils"

const CONSULTAR_IMAGEM = 'ia/chatgpt/imagem/nomes'
const CONSULTAR_IMOVEIS = 'ia/chatgpt/imoveis/indicacao'

const client = axios.create({
    baseURL: process.env.REACT_APP_AMBIENTE
});

export async function postImagemGpt(img) {
    return client.post(CONSULTAR_IMAGEM, img, Utils.getAuth())
}

export async function postImoveisGpt(imoveis) {
    return client.post(CONSULTAR_IMOVEIS, imoveis)
}