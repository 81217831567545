import { Box, Typography } from "@mui/material";
import { createClient } from "@supabase/supabase-js";
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import { useCommon } from '../../components/hooks/useCommon';
import { useForm } from "../../components/UseForm";
import * as Constantes from '../../util/constantes';
import * as Utils from '../../util/Utils';
import Filtros from "./Filtros";
import Markers from "./Markers";
import InfoWindowLocal from "./InfoWindowLocal";
import { debounce } from "lodash"
import * as GptService from '../../api/services/GptService'

const supabase = createClient("https://urksffqejgoyacvhltis.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVya3NmZnFlamdveWFjdmhsdGlzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQ4ODUxMDQsImV4cCI6MjA1MDQ2MTEwNH0.6LnuPY7mnKoHMMIuCYpZx72mBgcaauJDTKPhjZ10yxI");


const initialValues = {
    maiorPreco: 0,
    menorPreco: 0,
    maiorArea: 0,
    menorArea: 0,
    tipoImovel: 1,
    bairro: 1,
    vivareal: false,
    caixa: false,
    leiloes: true,
    venda: false,
    leilao: false,
    alugar: false
}

export default function TesteWrapper(props) {
    const { userDetails, setUserDetails } = props
    const { notificar, alterarLoad, alterarMensagemLoad, Common } = useCommon()
    const { width } = Utils.useWindowDimensions();
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE
    const LIMITE_CONSULTA = 350
    const [openPoints, setOpenPoints] = useState([])
    const [menu, setMenu] = useState(false)
    const [refinarAviso, setRefinarAviso] = useState(false)
    const [center, setCenter] = useState({ lat: -3.7443993362304924, lng: -38.47540182255099 })
    const [bounds, setBounds] = useState({
        east: -38.43662901558758,
        north: -3.833903783569521,
        south: -3.8681585377091494,
        west: -38.48941488900066
    })
    const [chave, setChave] = useState(1)
    const [zoom, setZoom] = useState(16)
    const [imoveis, setImoveis] = useState([])

    const {
        values,
        handleInputChange,
        erros,
        resetForm,
        setValues
    } = useForm(initialValues, true, () => { })

    useEffect(async () => {
        getImoveis();
    }, []);

    const handleMarkerClick = (p) => {
        setOpenPoints(openPoints.concat(p))
    }

    async function getImoveis() {
        let { data, error } = await supabase
            .from('imoveis')
            .select('*')
            .gt('lat', bounds.south)
            .lt('lat', bounds.north)
            .gt('lng', bounds.west)
            .lt('lng', bounds.east)
            .eq('origem_informacao', 2)
        setImoveis(data)
    }

    const indicarImoveis = async () => {
        let x = imoveis.map((im) => {
            return {
                id: im.id,
                lat: im.lat,
                lng: im.lng,
                tipo_imovel: im.tipo_imovel,
                valor_venda: im.valor_venda,
                area: im.area
            }
        })
        await GptService.indicarImoveisGpt(x).then(async response => {
            console.log(response)
        })
    }

    const getImoveisFiltro = debounce(async (bounds, filtros, chamada) => {
        let query = supabase
            .from('imoveis')
            .select('*')

        if (filtros.bairro > 1) {
            query = query.eq('bairro', filtros.bairro)
            let { data, error } = await supabase
                .from('bairros')
                .select('*')
                .eq('id', filtros.bairro)
            setCenter({ lat: data[0].lat, lng: data[0].lng })
            if (chamada) {
                bounds = {
                    east: data[0].lng + 0.01507402,
                    north: data[0].lat + 0.00862902,
                    south: data[0].lat - 0.00850046,
                    west: data[0].lng - 0.01524567
                }
                setBounds(bounds)
            }
        }

        query = query.gt('lat', bounds.south)
            .lt('lat', bounds.north)
            .gt('lng', bounds.west)
            .lt('lng', bounds.east)

        if (filtros.menorPreco > 0) query = query.gt('valor_venda', filtros.menorPreco)
        if (filtros.maiorPreco > 0) query = query.lt('valor_venda', filtros.maiorPreco)
        if (filtros.menorArea > 0) query = query.gt('area', filtros.menorArea)
        if (filtros.maiorArea > 0) query = query.lt('area', filtros.maiorArea)
        if (filtros.tipoImovel > 0) query = query.eq('tipo_imovel', filtros.tipoImovel)

        let array = []
        if (filtros.caixa) array.push(1)
        if (filtros.leiloes) array.push(2)
        if (filtros.vivareal) array.push(3)

        query = query.in('origem_informacao', array)
        let { data, error } = await query.limit(LIMITE_CONSULTA)
        setImoveis(data)

        setRefinarAviso(data.length >= LIMITE_CONSULTA)
    }, 800)

    const atualizarBounds = (b) => {
        setZoom(b.detail.zoom)
        setBounds(b.detail.bounds)
        setCenter(b.detail.center)
    }

    const alterarMenu = () => {
        setMenu(menu ? false : true)
    }
    const atualizarMapa = () => {
        setChave(chave + 1)
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const filtrar = async (filtros) => {
        await getImoveisFiltro(bounds, filtros, true)
        await sleep(1000)
        atualizarMapa()
    }

    const handleIdle = (e) => {
        getImoveisFiltro(bounds, values, false)
    }

    const handleCameraChange = (e) => {
        setImoveis([])
    }

    const handleClickOutside = () => {
        setOpenPoints([])
    };

    return (
        <Box sx={{ height: isMobile ? '700px' : '800px', width: '100%', marginBottom: '300px' }}>

            <Box onClick={() => setMenu(true)} sx={{ background: 'white', width: '100%', height: '30px', cursor: 'pointer' }}>
                <Typography>Abrir Menu</Typography>
            </Box>
            {refinarAviso &&
                <Box sx={{ background: 'red', width: '100%', height: '30px'}}>
                    <Typography>Limite de imóveis atingido. Refine sua consulta.</Typography>
                </Box>
            }
            <Filtros
                values={values}
                setValues={setValues}
                handleInputChange={handleInputChange}
                isMobile={isMobile}
                erros={erros}
                filtrar={filtrar}
                bounds={bounds}
                alterarMenu={alterarMenu}
                menu={menu}
            // indicarImoveis={indicarImoveis}
            />
            <APIProvider apiKey={'AIzaSyBgqjErywYT_C7YU2XD_33UqhNXQkcbKfo'}>
                <Map
                    onDragstart={(e) => { handleCameraChange(e) }}
                    onZoomChanged={(e) => { handleCameraChange(e) }}
                    onIdle={(e) => { handleIdle(e) }}
                    key={chave}
                    defaultZoom={zoom}
                    minZoom={14}
                    maxZoom={17}
                    defaultCenter={center}
                    mapId='521583b8d3f65fc3'
                    onBoundsChanged={(e) => { atualizarBounds(e) }}
                >
                    <Markers
                        points={imoveis}
                        handleMarkerClick={handleMarkerClick}
                    />

                </Map>

            </APIProvider>

            <InfoWindowLocal
                points={openPoints}
                handleClickOutside={handleClickOutside}
                isMobile={isMobile}
            />
        </Box >
    )
}