
import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps';

export default function Markers(props) {
    const { points, handleMarkerClick } = props

    const markerClick = (point) => {
        handleMarkerClick(point)
    }

    const porCentagem = (valorOriginal, valorVenda, porCento) => {
        return valorOriginal > 0 && 100 - (valorVenda / valorOriginal * 100) > porCento
    }


    if (points === undefined) return <></>
    return (
        <>
            {points.map((point) => (
                <AdvancedMarker
                    onClick={() => markerClick(point)}
                    position={{ lat: point.lat, lng: point.lng }}
                    key={point.id}
                >
                    <Pin
                        background={point.vendido === 1 ? '#787f8a' : (point.tipo_negocio === 1 ? '#b811b5' : '#fc032c')}
                        glyphColor={point.vendido === 1 ? '#787f8a' : (point.tipo_negocio === 1 ? porCentagem(point.valor_original, point.valor_venda, 35) ? '#e7fa1b' : '#b811b5' : '#fc032c')}
                        borderColor={point.tipo_negocio === 1 ? '#000' : '#000'}
                        scale={0.9}
                    />


                </AdvancedMarker>
            ))}
        </>
    );
};