import secureLocalStorage from "react-secure-storage";
import CartasService from '../api/services/CartasService';
import * as Constantes from './constantes';
import * as Cores from './Cores';

export const getAuth = () => {
    const userDetails = secureLocalStorage.getItem('userDetails')
    let headers = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + userDetails.token
        }
    }
    return (headers)
}

export const randomNumber = (min, max, precision) => {
    return Math.floor((min + Math.random() * (max - min)) * precision) / precision
}

export function verificarNoite() {
    const hours = new Date().getHours()
    const isDayTime = hours > 6 && hours < 19;
    if (isDayTime === true) {
        return false
    }
    return true
}

export function dividirArrayEmGrupos(arr, len) {
    if (arr === null || arr === undefined) return []
    var chunks = [], i = 0, n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}

export function temEstoque(lista, checkedEstoque) {
    if (checkedEstoque) {
        lista = lista.filter(x => Number(x.estoque) > 0)
    }
    return lista
}

export function cartasDesejo(lista, checkedDesejo) {
    if (checkedDesejo) {
        lista = lista.filter(x => x.ehDesejo)
    }
    return lista
}

export function cartasPossui(lista, checkedColecao) {
    if (!checkedColecao) {
        lista = lista.filter(x => !x.ehColecao)
    }
    return lista
}

export function stringVazia(str) {
    return (str === null || str === undefined || str === '')
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    return getWindowDimensions();
}


export const adicionarColecao = (carta, setCarta, userDetails, atualizarCarta, e, notificar) => {
    let id = carta.cartaTO === undefined ? carta.id : carta.cartaTO.id
    e.stopPropagation()
    if (carta.ehColecao) {
        CartasService.removerCartaLista({
            cartaId: id,
            clienteId: userDetails.id,
            tipoListaId: Constantes.TIPO_LISTA_COLECAO
        }).then(response => {
            atualizarCarta(carta, !carta.ehColecao, Constantes.TIPO_LISTA_COLECAO)
            setCarta({ ...carta, ehColecao: !carta.ehColecao })
        })
    } else {
        CartasService.adicionarCartaLista({
            cartaId: id,
            clienteId: userDetails.id,
            tipoListaId: Constantes.TIPO_LISTA_COLECAO
        }).then(response => {
            if (response !== undefined && response.erro) {
                notificar(response.mensagem, 'error')
            } else {
                atualizarCarta(carta, !carta.ehColecao, Constantes.TIPO_LISTA_COLECAO)
                setCarta({ ...carta, ehColecao: !carta.ehColecao })
            }
        })
    }
}

export const adicionarDesejo = (carta, setCarta, userDetails, atualizarCarta, e, notificar) => {
    let id = carta.cartaTO === undefined ? carta.id : carta.cartaTO.id
    e.stopPropagation()
    if (carta.ehDesejo) {
        CartasService.removerCartaLista({
            cartaId: id,
            clienteId: userDetails.id,
            tipoListaId: Constantes.TIPO_LISTA_DESEJO
        }).then(response => {
            setCarta({ ...carta, ehDesejo: !carta.ehDesejo })
            atualizarCarta(carta, !carta.ehDesejo, Constantes.TIPO_LISTA_DESEJO)
        })
    } else {
        CartasService.adicionarCartaLista({
            cartaId: id,
            clienteId: userDetails.id,
            tipoListaId: Constantes.TIPO_LISTA_DESEJO
        }).then(response => {
            if (response !== undefined && response.erro) {
                notificar(response.mensagem, 'error')
            } else {
                setCarta({ ...carta, ehDesejo: !carta.ehDesejo })
                atualizarCarta(carta, !carta.ehDesejo, Constantes.TIPO_LISTA_DESEJO)
            }
        })
    }
}
export const adicionarTroca = (carta, setCarta, userDetails, atualizarCarta, e, notificar) => {
    let id = carta.cartaTO === undefined ? carta.id : carta.cartaTO.id
    e.stopPropagation()
    setCarta({ ...carta, ehTroca: !carta.ehTroca })
    atualizarCarta(carta, !carta.ehTroca, Constantes.TIPO_LISTA_TROCA)
    if (carta.ehTroca) {
        CartasService.removerCartaLista({
            cartaId: id,
            clienteId: userDetails.id,
            tipoListaId: Constantes.TIPO_LISTA_TROCA
        }).then(response => {
            setCarta({ ...carta, ehTroca: !carta.ehTroca })
            atualizarCarta(carta, !carta.ehTroca, Constantes.TIPO_LISTA_DESEJO)
        })
    } else {
        CartasService.adicionarCartaLista({
            cartaId: id,
            clienteId: userDetails.id,
            tipoListaId: Constantes.TIPO_LISTA_TROCA
        }).then(response => {
            if (response !== undefined && response.erro) {
                notificar(response.mensagem, 'error')
            } else {
                setCarta({ ...carta, ehTroca: !carta.ehTroca })
                atualizarCarta(carta, !carta.ehTroca, Constantes.TIPO_LISTA_DESEJO)
            }
        })
    }
}

export const alterarCarrinho = (index, quant, carta, carrinho, setCarrinho, desconto = 0) => {
    let lista = []
    let inseriu = false;
    if (carta === null) {
        lista = carrinho.map((item, i) => {
            if (i === index) {
                let total = item.quantidade + quant
                if (total > 0) {
                    if (total <= item.estoque) {
                        inseriu = true;
                        return ({
                            ...item,
                            quantidade: total
                        })
                    } else {
                        return item
                    }
                }
            } else {
                return item
            }
        }).filter(item => item !== undefined)
    } else {
        let achou = false
        lista = carrinho.map(x => {
            achou = x.id === carta.cartaTO.id
            if (x.id === carta.cartaTO.id && x.quantidade + 1 <= x.estoque) {
                inseriu = true;
                return {
                    id: x.id,
                    valor: x.valor,
                    quantidade: x.quantidade + 1,
                    imagem: x.imagem,
                    nome: x.nome,
                    estoque: x.estoque,
                    desconto: desconto
                }
            } else {
                return x
            }
        })

        if (!achou) {
            inseriu = true;
            lista = carrinho.concat([{
                id: carta.cartaTO.id,
                valor: carta.cartaTO.valor,
                quantidade: quant,
                imagem: carta.cartaTO.imgUrl,
                nome: carta.cartaTO.nome,
                estoque: carta.cartaTO.estoque
            }])
        }
    }
    setCarrinho(lista)
    return inseriu
}

export function distanciaPontos(mk1, mk2) {
    var R = 6371.0710; // Radius of the Earth in miles
    var rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    return d;
}

export const barra = () => {
    return {
        "::-webkit-scrollbar": {
            width: "0px",
            height: '4px'
        },
        "::-webkit-scrollbar-track": {
            backgroundColor: '#fff5f5'
        },
        "::-webkit-scrollbar-thumb": {
            background: Constantes.COR_SECUNDARIA_ROSA,
            borderRadius: "15px",
            height: "1px"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: Constantes.COR_SECUNDARIA_ROSA,
            maxHeight: "1px",
        }
    }
}


export const barraVertical = () => {
    return {
        "::-webkit-scrollbar": {
            width: "4px",
            height: '4px'
        },
        "::-webkit-scrollbar-track": {
            backgroundColor: '#fff5f5'
        },
        "::-webkit-scrollbar-thumb": {
            background: Cores.COR_PRINCIPAL,
            borderRadius: "15px",
            height: "1px"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: Constantes.COR_SECUNDARIA_ROSA,
            maxHeight: "1px",
        }
    }
}