import { InputAdornment, TextField } from "@mui/material";
export default function Input(props) {

    const { id = '', name, label, value, error = null, onChange, inputPropText = '', background = 'white', startInputPropText = '', ...other } = props

    return (
        <TextField
            id={id}
            variant="standard"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            sx={{
                background: background, borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                "& .MuiFormLabel-root": {
                    marginLeft: '6px'
                }
            }}
            InputProps={(inputPropText !== '' || startInputPropText !== '') && {
                endAdornment: (
                    <InputAdornment position="end">
                        {inputPropText}
                    </InputAdornment>
                ),
                startAdornment: (
                    <InputAdornment position="start">
                        {startInputPropText}
                    </InputAdornment>
                )
            }}
            {...other}
            {...(error && { error: true, helperText: error })}
        />
    )
}