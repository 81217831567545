import Input from './Input'
import RadioGroup from './RadioGroup'
import Select from './Select'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import Button from './Button'
import ActionButton from './ActionButton'
import AutocompleteCarta from './AutocompleteCarta'
import InputNomeCarta from './InputNomeCarta'
import AutocompleteCartaFiltro from './AutocompleteCartaFiltro'
import BuscarCartaNomeId from './BuscarCartaNomeId'
import SelectEstado from './SelectEstado'
import Dados from './input/Dados'
import Fab from './buttons/Fab'
import SortCartas from './input/SortCartas'
import SelectTipoImovel from './input/SelectTipoImovel'
import SelectBairro from './input/SelectBairro'
import CircularProgressBarComIcone from './CircularProgressBarComIcone'

const Controls = {
    SelectEstado,
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    AutocompleteCarta,
    InputNomeCarta,
    AutocompleteCartaFiltro,
    BuscarCartaNomeId,
    CircularProgressBarComIcone,
    Fab,
    SortCartas,
    Dados,
    SelectTipoImovel,
    SelectBairro,
}

export default Controls
